
$(document).ready(function () {
    $('.modal-iframe').click(function () {
        e = $(this);
        $('iframe', '#iframeContent').load(e.data("target"));
        $('#iframeContent').modal({show: true})
    });

    $('.share_popup').on("click", function (e) {
        e.preventDefault();
        var t = $(this).data("target"), r = "_blank", i = "600", n = "460", a = "width=" + i + ",height=" + n;
        window.open(t, r, a)
    });

    /* 	$('#itemcard_order_button_form_indiv select').attr('disabled', 'disabled');
     $('#itemcard_order_button_form_std select').removeAttr('disabled');
     */
    $('.itemcard_order_button_radio_wrapper_inner').change('input', function () {
        if ($('#itemcard_order_type_choice_1').is(":checked")) {
            $('#itemcard_order_button_form_indiv_outer').addClass('itemcard_order_button_inactive');
            $('#itemcard_order_button_form_std_outer').removeClass('itemcard_order_button_inactive');
            $('div[u="slides"] > div:nth-child(2) > div > img[cust_img="0"]').click();
            $('#itemcard_order_button_form_indiv select').attr('disabled', 'disabled');
            $('#itemcard_order_button_form_std select').removeAttr('disabled');
        } else {
            $('#itemcard_order_button_form_indiv_outer').removeClass('itemcard_order_button_inactive');
            $('#itemcard_order_button_form_std_outer').addClass('itemcard_order_button_inactive');
            $('div[u="thumbnavigator"] img[cust_img="1"]').click();
            $('#itemcard_order_button_form_std select').attr('disabled', 'disabled');
            $('#itemcard_order_button_form_indiv select').removeAttr('disabled');
        }
    });
    $('.customize_delete_pic').click(function (e) {
        var obj = $(this);
        e.preventDefault();
        var request = $.ajax({
            url: '/module/dcshop/b2c/ajax.php' + $(obj).attr('href'),
            method: "POST"
        });

        request.done(function (msg) {
            var obj2 = $(obj).parents(".cust_img_area")
            $(obj2).html(msg);
            $(obj2).on("change", "input[type='file']", function () {
                $(obj2).find('input[type="text"]').val(this.files[0].name);
            })
        });
    });

    $('#header_shop_icons .header_icon_search').click(function () {
        $('#header_4').slideToggle('fast');
        $('#header_4 #input_search').focus();
    });

    $(".form_search").submit(function (e) {
        if (!$(this).find('#input_search').val()) {
            e.preventDefault(e);
            e.stopPropagation(e);
        }
    });
});

function changeBg(a, img1, img2) {
    if (!a) {
        return true;
    }
    if (a.style.backgroundImage == "url(" + img1 + ")") {
        a.style.backgroundImage = "url(" + img2 + ")";
    } else {
        a.style.backgroundImage = "url(" + img1 + ")";
    }
    return true;
}

function toggleOn(a) {
    var e = document.getElementById(a);
    if (!e) {
        return true;
    }
    e.style.display = "block";
    return true;
}

function toggleOff(a) {
    var e = document.getElementById(a);
    if (!e) {
        return true;
    }
    e.style.display = "none";
    return true;
}

function toggle(a) {
    var e = document.getElementById(a);
    if (e.style.display != "block") {
        if (!e) {
            return true;
        }
        e.style.display = "block";
    } else {
        e.style.display = "none";
    }
}

function toggleOnnb(a) {
    var e = document.getElementById(a);
    if (!e) {
        return true;
    }
    e.style.display = "";
    return true;
}

function toggleOffnb(a) {
    var e = document.getElementById(a);
    if (!e) {
        return true;
    }
    e.style.display = "none";
    return true;
}

function togglenb(a) {
    var e = document.getElementById(a);
    if (e.style.display != "") {
        if (!e) {
            return true;
        }
        e.style.display = "";
    } else {
        e.style.display = "none";
    }
}

function toggleByClass(a) {
    var e = document.getElementByClassName(a);
    if (e.style.display != "block") {
        if (!e) {
            return true;
        }
        e.style.display = "block";
    } else {
        e.style.display = "none";
    }
}

function MM_jumpMenu(targ, selObj, restore) { //v3.0
    eval(targ + ".location='" + selObj.options[selObj.selectedIndex].value + "'");
    if (restore) {
        selObj.selectedIndex = 0;
    }
}

function openPopup(URL, WIDTH, HEIGHT) {
    if (!WIDTH) {
        WIDTH = 600;
    }
    if (!HEIGHT) {
        HEIGHT = 600;
    }
    WIDTH = 600;
    HEIGHT = 600;
    popup = window.open(URL, "popup", "width=" + WIDTH + ", height=" + HEIGHT + ", scrollbars=no");
}

function showLayer(lyr) {
    makeHistory(lyr);
    document.getElementById(currentLayer).className = 'hide';
    document.getElementById(lyr).className = 'show';
    currentLayer = lyr;
}

function showTab(lyr) {
    document.getElementById(currentTab).className = 'taboff';
    document.getElementById(lyr).className = 'tabon';
    currentTab = lyr;
}

function makeHistory(newHash) {
    window.location.hash = "_" + newHash;
    expectedHash = window.location.hash;
    return true;
}

function handleHistory() {
    if (window.location.hash != expectedHash) {
        expectedHash = window.location.hash;
        if (expectedHash.match('tab')) {
            showLayer(expectedHash.substring(2));
        }
    }
    return true;
}

function pollHash() {
    handleHistory();
    window.setInterval("handleHistory()", 200);
    return true;
}

function toggleDiv(id) {
    /*
     var obj=document.getElementsByTagName("div");
     for(i=0;i<obj.length;i++) {
     if(obj[i].collectionEntityClassName =="func") {
     obj[i].style.display="none";
     }
     }
     */
    if (document.getElementById(id).style.display == 'none') {
        document.getElementById(id).style.display = 'block';
        document.getElementById('head_' + id).className = 'function_cat open';
    } else {
        document.getElementById(id).style.display = 'none';
        document.getElementById('head_' + id).className = 'function_cat closed';
    }
}

function MM_swapImgRestore() { //v3.0
    var i, x, a = document.MM_sr;
    for (i = 0; a && i < a.length && (x = a[i]) && x.oSrc; i++) {
        x.src = x.oSrc;
    }
}

function MM_preloadImages() { //v3.0
    var d = document;
    if (d.images) {
        if (!d.MM_p) {
            d.MM_p = new Array();
        }
        var i, j = d.MM_p.length, a = MM_preloadImages.arguments;
        for (i = 0; i < a.length; i++) {
            if (a[i].indexOf("#") != 0) {
                d.MM_p[j] = new Image;
                d.MM_p[j++].src = a[i];
            }
        }
    }
}

function MM_findObj(n, d) { //v4.01
    var p, i, x;
    if (!d) {
        d = document;
    }
    if ((p = n.indexOf("?")) > 0 && parent.frames.length) {
        d = parent.frames[n.substring(p + 1)].document;
        n = n.substring(0, p);
    }
    if (!(x = d[n]) && d.all) {
        x = d.all[n];
    }
    for (i = 0; !x && i < d.forms.length; i++) {
        x = d.forms[i][n];
    }
    for (i = 0; !x && d.layers && i < d.layers.length; i++) {
        x = MM_findObj(n, d.layers[i].document);
    }
    if (!x && d.getElementById) {
        x = d.getElementById(n);
    }
    return x;
}

function MM_swapImage() { //v3.0
    var i, j = 0, x, a = MM_swapImage.arguments;
    document.MM_sr = new Array;
    for (i = 0; i < (a.length - 2); i += 3) {
        if ((x = MM_findObj(a[i])) != null) {
            document.MM_sr[j++] = x;
            if (!x.oSrc) {
                x.oSrc = x.src;
            }
            x.src = a[i + 2];
        }
    }
}

function isTouchDevice() {
    var el = document.createElement('div');
    el.setAttribute('ontouchstart', 'return;');
    if (typeof el.ontouchstart == "function") {
        return true;
    } else {
        return false
    }
}

function dc_checkVat(elem, value, allReverseChargeAllowedCountryCodes, currentCountryCode) {

    error = false;
    if (elem.is(':visible') && value != '' && allReverseChargeAllowedCountryCodes.length > 0
        && $.inArray(currentCountryCode, allReverseChargeAllowedCountryCodes) != -1) {
        var result = jsvat.checkVAT(value);
        if (!result.isValid) {
            elem.parent().addClass('has-danger');
            error = true;
        } else {
            elem.parent().removeClass('has-danger');
        }
    } else {
        if (elem.parent().hasClass('has-danger')) {
            elem.parent().removeClass('has-danger');
        }
    }
    return error;
}

function setCurrentToolbarClicked(el) {
    return;
}

//mejs.i18n.language('de');

$.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^]*)').exec(window.location.href);
    if (results == null) {
        return null;
    } else {
        return results[1] || 0;
    }
}

$(document).ready(function () {

    $('.share_popup').on("click", function (e) {
        e.preventDefault();
        var t = $(this).data("target"), r = "_blank", i = "600", n = "460", a = "width=" + i + ",height=" + n;
        window.open(t, r, a)
    });

    /* 	$('#itemcard_order_button_form_indiv select').attr('disabled', 'disabled');
     $('#itemcard_order_button_form_std select').removeAttr('disabled');
     */
    $('.itemcard_order_button_radio_wrapper_inner').change('input', function () {
        if ($('#itemcard_order_type_choice_1').is(":checked")) {
            $('#itemcard_order_button_form_indiv_outer').addClass('itemcard_order_button_inactive');
            $('#itemcard_order_button_form_std_outer').removeClass('itemcard_order_button_inactive');
            $('div[u="slides"] > div:nth-child(2) > div > img[cust_img="0"]').click();
            $('#itemcard_order_button_form_indiv select').attr('disabled', 'disabled');
            $('#itemcard_order_button_form_std select').removeAttr('disabled');
        } else {
            $('#itemcard_order_button_form_indiv_outer').removeClass('itemcard_order_button_inactive');
            $('#itemcard_order_button_form_std_outer').addClass('itemcard_order_button_inactive');
            $('div[u="thumbnavigator"] img[cust_img="1"]').click();
            $('#itemcard_order_button_form_std select').attr('disabled', 'disabled');
            $('#itemcard_order_button_form_indiv select').removeAttr('disabled');
        }
    });
    $('.customize_delete_pic').click(function (e) {
        var obj = $(this);
        e.preventDefault();
        var request = $.ajax({
            url: '/module/dcshop/b2c/ajax.php' + $(obj).attr('href'),
            method: "POST"
        });

        request.done(function (msg) {
            var obj2 = $(obj).parents(".cust_img_area")
            $(obj2).html(msg);
            $(obj2).on("change", "input[type='file']", function () {
                $(obj2).find('input[type="text"]').val(this.files[0].name);
            })
        });
    });
});
function getCurrentAnimationPositionPercent(wrapperBoundingRect) {
    let percent = 100 - ((wrapperBoundingRect.height + wrapperBoundingRect.top) / wrapperBoundingRect.height * 100);

    if (percent > 100) {
        percent = 100;
    }

    return parseInt(percent);
}

function getAnimationTransformStyle(transforms) {
    let transformStyle = "";
    if (transforms.transformX !== undefined) {
        transformStyle += " translateX(" + transforms.transformX + "%)";
    }
    if (transforms.transformY !== undefined) {
        transformStyle += " translateY(" + transforms.transformY + "%)";
    }
    if (transforms.transformZ !== undefined) {
        transformStyle += " translateZ(" + transforms.transformZ + "%)";
    }
    if (transforms.rotate !== undefined) {
        transformStyle += " rotate(" + transforms.rotate + "deg)";
    }
    if (transforms.scale !== undefined) {
        transformStyle += " scale(" + transforms.scale + ")";
    }

    return transformStyle;
}

function setAnimationStyleStates(state, el, remove = false) {
    if (state.options.transformX !== undefined && !remove) {
        el.hasState = {
            transformX: state.options.transformX
        }
    } else {
        if (el.hasState && el.hasState.transformX) {
            delete el.hasState.transformX;
        }
    }

    if (state.options.transformY !== undefined && !remove) {
        el.hasState = {
            transformY: state.options.transformY
        }
    } else {
        if (el.hasState && el.hasState.transformY) {
            delete el.hasState.transformY;
        }
    }

    if (state.options.transformZ !== undefined && !remove) {
        el.hasState = {
            transformZ: state.options.transformZ
        }
    } else {
        if (el.hasState && el.hasState.transformZ) {
            delete el.hasState.transformZ;
        }
    }

    if (state.options.rotate !== undefined && !remove) {
        el.hasState = {
            rotate: state.options.rotate
        }
    } else {
        if (el.hasState && el.hasState.rotate) {
            delete el.hasState.rotate;
        }
    }

    if (state.options.scale !== undefined && !remove) {
        el.hasState = {
            scale: state.options.scale
        }
    } else {
        if (el.hasState && el.hasState.scale) {
            delete el.hasState.scale;
        }
    }

    if (state.options.position !== undefined && !remove) {
        el.hasState = {
            position: state.options.position
        }
    } else {
        if (el.hasState && el.hasState.position) {
            delete el.hasState.position;
        }
    }

    if (state.options.top !== undefined && !remove) {
        el.hasState = {
            top: state.options.top
        }
    } else {
        if (el.hasState && el.hasState.top) {
            delete el.hasState.top;
        }
    }

    if (state.options.left !== undefined && !remove) {
        el.hasState = {
            left: state.options.left
        }
    } else {
        if (el.hasState && el.hasState.left) {
            delete el.hasState.left;
        }
    }

    if (state.options.opacity !== undefined && !remove) {
        el.hasState = {
            opacity: state.options.opacity
        }
    } else {
        if (el.hasState && el.hasState.opacity) {
            delete el.hasState.opacity;
        }
    }

    if (state.options.width !== undefined && !remove) {
        el.hasState = {
            width: state.options.width
        }
    } else {
        if (el.hasState && el.hasState.width) {
            delete el.hasState.width;
        }
    }

    if (state.options.height !== undefined && !remove) {
        el.hasState = {
            height: state.options.height
        }
    } else {
        if (el.hasState && el.hasState.height) {
            delete el.hasState.height;
        }
    }
}

function addAnimationStyleObject(optionsOfState, el) {
    if (el.styles) {
        el.styles = {...el.styles, ...optionsOfState};
    } else {
        el.styles = optionsOfState;
    }
}

function removeAnimationStyleObject(optionsOfState, el) {
    if (el.styles) {
        for (let key of Object.keys(optionsOfState)) {
            delete el.styles[key];
        }
    }
}

function setAnimationStyle(el, optionsOfState = false) {
    if (el.styles) {
        let transforms = {};
        let arrTransformStyles = [
            "transformX",
            "transformY",
            "transformZ",
            "rotate",
            "scale",
        ]

        for (let key of Object.keys(el.styles)) {
            if (arrTransformStyles.includes(key)) {
                transforms[key] = el.styles[key];
            } else {
                let styleString = el.styles[key];
                el.obj.style[key] = styleString;
            }
        }

        if (optionsOfState !== false) {
            for (let key of Object.keys(optionsOfState)) {
                el.obj.style[key] = "";
            }
        }

        el.obj.style.transform = getAnimationTransformStyle(transforms);
    }
}

function animateAndScroll(settings = []) {

    for (const wrapper of document.querySelectorAll(settings.wrapper)) {
        for (let elSettings of settings.els) {
            elSettings.obj = wrapper.querySelector(elSettings.selector);
        }

        document.addEventListener("scroll", function () {
            const wrapperBoundingRect = wrapper.getBoundingClientRect();
            let percent = getCurrentAnimationPositionPercent(wrapperBoundingRect);
            for (let el of settings.els) {
                for (let state of el.states) {
                    let animate = false;
                    if (state.end !== undefined && state.end < 100) {
                        if (state.start <= percent && state.end >= percent) {
                            animate = true;
                        }
                    } else {
                        if (state.start <= percent) {
                            animate = true;
                        }
                    }
                    if (animate) {
                        addAnimationStyleObject(state.options, el);
                        setAnimationStyle(el);
                    } else {
                        setAnimationStyle(el, state.options);
                        removeAnimationStyleObject(state.options, el);
                    }
                    if (el.styles) {
                        if (el.styles.position && el.styles.position === "fixed") {
                            let elHeight = el.obj.clientHeight;
                            if ((wrapperBoundingRect.y + wrapperBoundingRect.height) < elHeight) {
                                requestAnimationFrame(function () {
                                    el.obj.style.position = "absolute";
                                    el.obj.style.top = (wrapperBoundingRect.height - elHeight) + "px";
                                });
                            }
                        }
                    }
                }
            }
        });
    }
}
!function (a) {
    a.fn.datepicker.dates.de = {
        days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
        daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
        daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
        today: "Heute",
        monthsTitle: "Monate",
        clear: "Löschen",
        weekStart: 1,
        format: "dd.mm.yyyy"
    }
}(jQuery);

$(document).ready(function () {
    $('.datepicker').datepicker({
        format: "dd.mm.yyyy",
        language: "de"
    });
});
$(document).ready(function () {

    $('.filter > .filter_headline').click(function () {
        var active = $(this).hasClass('clicked');

        $('.filterbox .filter_headline').removeClass('clicked');
        $('.filterbox .filter_toggle').slideUp('fast');

        if (active != false) {
            $(this).removeClass('clicked');
            $(this).next('.filter_toggle').slideUp('fast');
        } else {
            $(this).addClass('clicked');
            $(this).next('.filter_toggle').slideDown('fast');
        }
    });
});

$(document).on('click', function (e) {
    var target = $(e.target);
    if ($(e.target).is('.active.filterbox-mobilebutton')) {
        $('.filterbox-mobilebutton').removeClass('active');
        $('.filterbox').slideUp('fast');
        return;
    }
    if ($('.filterbox-mobilebutton').is(':visible')) {
        if (target.closest('.filterbox-mobilebutton').length || target.closest('.filterbox').length) {
            $('.filterbox-mobilebutton').addClass('active');
            $('.filterbox').slideDown('fast');
        } else {
            if ($('.filterbox-mobilebutton').hasClass('active')) {
                $('.filterbox-mobilebutton').removeClass('active');
                $('.filterbox').slideUp('fast');
            }
        }
    }
});
$(document).ready(function () {
    var flashMessage = $('.flashMessage');
    var flashSpeed = 'slow';
    var flashDelay = '4000';

    $(window).on('load', function () {
        flashMessage.fadeIn(flashSpeed);

        $(function () {
            setTimeout(function () {
                flashMessage.fadeOut(flashSpeed);
            }, flashDelay);
        });
        flashMessage.click(function () {
            $(this).fadeOut(flashSpeed);
        });
    });
});

$(document).ready(function () {
    var visibleInputs = $('input[type!=hidden]:visible'),
        inputForm,
        currInput,
        lastInputInForm;
    $(visibleInputs).each(function () {
        currInput = $(this);
        inputForm = currInput.parents('form');
        formID = $(inputForm).attr('id');
        if (formID !== 'form_itemcard') {
            lastInputInForm = $(inputForm).find('input[type!=hidden]:visible').last();
            lastTextInputInForm = $(inputForm).find('input[type!=checkbox]:visible').last();
            if ((currInput[0] == lastInputInForm[0]) || (currInput[0] == lastTextInputInForm[0])) {
                $(currInput).on('keyup', function (e) {
                    if (e.which === 13) {
                        $(this).parents('form').submit();
                        return false;
                    }
                });
            }
        }
    });
});
$(document).ready(function () {
    $('input.inputfile').change(function () {
        var file = $(this).val().split('\\').pop();
        if (file == '') {
            var data_files = $(this).prop('data-files');
            file = data_files[0].name;
            $(this).attr('value', file);
        }
        var id = $(this).attr('id');
        var label = $('label[for="' + id + '"]');
        label.addClass('active');
        label.children('span').empty().append(file);
    });
});
$(document).ready(function () {
    if ($('.dc_isotope .collection_wrapper.isList').length > 0) {

        var groupFilter = $.urlParam('group_filter');
        var groupFilterInit = "*";

        if (groupFilter != null && groupFilter !== 0) {
            groupFilterInit = '.collection_group_' + groupFilter;
        }

        var $grid = $('.dc_isotope .collection_wrapper.isList').isotope({
            itemSelector: '.collection_list',
            layoutMode: 'masonry',
            filter: groupFilterInit
        });

        $('.dc_isotope .group_filter_area a').on('click', function (event) {
            event.preventDefault();
            var filterValue = $(this).attr('data-filter');
            if (filterValue !== "*") {
                filterValue = "." + filterValue;
            }
            $grid.isotope({filter: filterValue});
            $(this).closest('.group_filter_area').children('.group_filter').removeClass('active');
            $(this).parent().addClass('active');
        });
    }
});

$(document).ready(function () {
    $('body').on('click', '.video-select', function (e) {
        e.preventDefault;
        videoPath = $(this).attr('data-videoPath');
        videoName = $(this).attr('data-videoName');
        videoId = $(this).attr('data-videoId');
        $('.mcs-item').each(function (e) {
            $(this).find('a').removeClass('mz-thumb-selected');
        });
        $('.item_video_container').removeClass('active');
        $('.item_video_container').find('.video_container').hide();
        $(this).addClass('mz-thumb-selected');
        $('#video_' + videoId).show();
        $('#video_' + videoId).parents('.item_video_container').addClass('active');
        return false;
    });

    $('body').on('click', '.mz-thumb', function (e) {
        e.preventDefault;
        if ($(e.target).is('.video-select') || $(e.target).parent().is('.video-select')) {
            return;
        }
        $('.mcs-item').each(function (e) {
            $(this).find('a').removeClass('mz-thumb-selected');
        });
        $(this).addClass('mz-thumb-selected');
        $('.item_video_container').removeClass('active');
        $('.item_video_container').find('.video_container').hide();
    });
});
function initHoverintentItemlistOwl(property) {
    initHoverintentItemlist($(property.target).find('.itemlist'));
}

function initHoverintentItemlist(el) {
    var cur = el;
    //hoverintents fix touchdevices
    if (!isTouchDevice()) {
        cur.hoverIntent({
            over: function (){
                $(this).addClass('hoverintent');
            },
            timeout: 200,
            out: function (){
                $(this).removeClass('hoverintent');
            }
        });
    } else {
        cur.click(function (event) {
            if (!$(this).hasClass('hoverintent')) {
                event.preventDefault();
                $(document).find('.hoverintent').removeClass('hoverintent');
                $(this).addClass('hoverintent');
            }
        });
    }
}

$(document).ready(function () {
    $('.shop_item_preview > .owl-carousel').each(function () {
        if (!$(this).closest('#user_queue').length) {
            $(this).owlCarousel({
                loop: true,
                items: 5,
                margin: 20,
                autoplay: true,
                autoHeight: true,
                autoWidth: false,
                navText: ['<i class="icon icon-angle-left"></i>', '<i class="icon icon-angle-right"></i>'],
                nav: true,
                autoplayHoverPause: true,
                onInitialized: initHoverintentItemlistOwl,
                responsive: {
                    0: {
                        items: 1,
                        margin: 0,
                    },
                    481: {
                        items: 2,
                    },
                    768: {
                        items: 3
                    },
                    1024: {
                        items: 4
                    },
                    1540: {
                        items: 5
                    }
                }
            });
        }
    });
});
//triggers on click on any links that start with # --> meaning they are scrollmarks and no new pages
// and smoothly scroll to it. the scrollTop value is a calculation that includes the sticky header
$(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    if ($(this).parents('.modal').length > 0) {
        return;
    }
    if ($(this).attr('href') == "#") {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    } else {
        if ($('.stickySubnavigation').length) {

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - ($('#header').outerHeight() + $('.stickySubnavigation').outerHeight() + 20)
            }, 500);
        } else {
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - ($('#header').outerHeight() + 20)
            }, 500);
        }

    }
});
function toggle_mobile_menu(menu, overlay, toggle) {
    if (!menu.hasClass('active')) {
        show_mobile_menu(menu, overlay, toggle);
    } else {
        hide_mobile_menu(menu, overlay, toggle);
    }
}

function show_mobile_menu(menu, overlay, toggle) {
    $('.modal').modal('hide');
    toggle.addClass('active');
    overlay.fadeIn('fast', function () {
        menu.animate({
            right: 0
        }, 200, function () {
            menu.addClass('active');
        });
    });
}

function hide_mobile_menu(menu, overlay, toggle) {
    toggle.removeClass('active');
    menu.animate({
        right: -340
    }, 200, function () {
        menu.css({
            right: ''
        }).removeClass('active');
        overlay.fadeOut('fast');
    });
}

function addToggleButtonForMobileMenu(menu) {
    menu.find(' li').each(function () {
        if ($(this).children('ul').length > 0) {
            $(this).children('a').append('<div class="toggleSubnavi"><i class="icon icon-angle-down"></i></div>');
            $(this).addClass('hasChildren');

            $(this).find('.toggleSubnavi').parent('a').on('click', function (event) {
                var nextUl = $(this).next('ul');
                if (nextUl.is(':visible')) {
                    nextUl.slideUp('fast');
                    $(this).parent().removeClass('active_tree');
                    $(this).closest('li').removeClass('active_tree');
                    nextUl.find('.active_tree').removeClass('active_tree');
                    nextUl.find('ul').slideUp('fast');
                } else {
                    nextUl.slideDown('fast');
                    $(this).parent().addClass('active_tree');
                    $(this).closest('li').addClass('active_tree');
                }
                event.preventDefault();
            });
        }
    });
}
function initScrolltopbutton(scrolltop) {
    if ($(window).scrollTop() > 0) {
        scrolltop.addClass('sticky');
    } else {
        scrolltop.removeClass('sticky');
    }

    $(window).scroll(function () {
        if ($(window).scrollTop() > 0) {
            scrolltop.addClass('sticky');
        } else {
            scrolltop.removeClass('sticky');
        }
    });

    if (scrolltop) {
        scrolltop.click(function () {
            $('html,body').animate({
                scrollTop: 0
            }, 800);
        })
    }
}

let scrollActivateItems = document.querySelectorAll('.scrollActivate');
if (scrollActivateItems.length) {
    document.addEventListener("scroll", function (e) {
        for (let scrollActivate of scrollActivateItems) {
            let boundingClientRect = scrollActivate.getBoundingClientRect();
            if (boundingClientRect) {
                let startPosition = boundingClientRect.y + boundingClientRect.height / 2;
                let windowOuterHeight = window.outerHeight;
                if (boundingClientRect.height > windowOuterHeight) {
                    startPosition = boundingClientRect.y;
                }
                if ((startPosition) < windowOuterHeight / 3 * 2) {
                    scrollActivate.classList.add('active');
                }
            }
        }
    });
}
function itemsearchSuggest(searchString) {
    var data = {},
        dataDiv = $('#search_data'),
        jsonData;

    data.company = $(dataDiv).data("company");
    data.shop_code = $(dataDiv).data("shop_code");
    data.language_code = $(dataDiv).data("language_code");
    data.site_language = $(dataDiv).data("site_language");
    data.item_source = $(dataDiv).data("item_source");
    data.site_code = $(dataDiv).data("site_code");
    data.sid = $(dataDiv).data("sid");
    data.image_config = $(dataDiv).data("image_config");
    data.default_img = $(dataDiv).data("default_img");
    data.input_search = searchString;
    data.customer_no = $(dataDiv).data("customer_no");
    jsonData = JSON.stringify(data);
    $.ajax({
        type: 'POST',
        url: '/module/dcshop/b2c/ajax_itemsearch.php',
        data: jsonData,
        dataType: "json",
        converters: {
            'text json': true
        },
        beforeSend: function () {
            $('#itemsearch_suggestion_wrapper').hide().empty();
        }
    }).done(function (data) {
        $('#itemsearch_suggestion_wrapper').html(data).show(0, function () {
            var searchSuggestions = $('.search_suggestion');
            $(document).on('keydown', function (e) {
                var evt = e || window.event;
                if (evt.keyCode == 38 || evt.keyCode == 40) {
                    e.preventDefault();
                    return false;
                }
            });
            $('#input_search').on('keyup', function (e) {
                var evt = e || window.event,
                    code = (e.keyCode ? e.keyCode : e.which);
                if (evt.keyCode === 40 && typeof searchSuggestions !== 'undefined' && searchSuggestions.length) { //Pfeil nach unten
                    $(searchSuggestions).first().addClass('hasfocus').focus();
                }
            });
            $('.search_suggestion').on('click', function () {
                var newHref = $(this).data('newhref');
                if (typeof newHref !== 'undefined' && newHref.length) {
                    window.location.href = newHref;
                }
            });
            $('.search_suggestion').on('keyup', function (e) {
                var newHref = $(this).data('newhref'),
                    evt = e || window.event,
                    code = (evt.keyCode ? evt.keyCode : evt.which),
                    currSuggestion = $(this),
                    currIndex = $(currSuggestion).data('suggestionindex'),
                    newIndex,
                    newSuggestion;
                if (evt.keyCode == 13 && typeof newHref !== 'undefined' && newHref.length) {
                    window.location.href = newHref;
                } else if (evt.keyCode === 40 && typeof searchSuggestions !== 'undefined' && searchSuggestions.length) { //Pfeil nach unten
                    newIndex = currIndex + 1;
                    newSuggestion = $('#item_search_suggestions').find("[data-suggestionIndex='" + newIndex + "']");
                    if (typeof newSuggestion !== 'undefined' && newSuggestion.length) {
                        $(newSuggestion).siblings().removeClass('hasfocus');
                        $(newSuggestion).addClass('hasfocus').focus();
                    }
                } else if (evt.keyCode === 38 && typeof searchSuggestions !== 'undefined' && searchSuggestions.length) { //Pfeil nach oben
                    newIndex = currIndex - 1;
                    newSuggestion = $('#item_search_suggestions').find("[data-suggestionIndex='" + newIndex + "']");
                    if (typeof newSuggestion !== 'undefined' && newSuggestion.length) {
                        $(newSuggestion).siblings().removeClass('hasfocus');
                        $(newSuggestion).addClass('hasfocus').focus();
                    } else {
                        $('.search_suggestion').removeClass('hasfocus');
                        $('#input_search').focus();
                    }
                }
            });
        });
    }).fail(function () {
        $('#itemsearch_suggestion_wrapper').hide().empty();
    });
}

function initSearchSuggest(){
    if ($('#search_data').length) {
        var queryDelay = 300,
            queryTimeout;

        $('#input_search').on('keyup', function (e) {
            queryString = $('#input_search').val();
            if (queryTimeout) {
                window.clearTimeout(queryTimeout);
                queryTimeout = null;
            }
            if (e.keyCode !== 13 && e.keyCode !== 40 && e.keyCode !== 38 && e.keyCode !== 8 && queryString.length > 3) {
                queryTimeout = window.setTimeout(function () {
                    itemsearchSuggest(queryString);
                }, queryDelay);
            }
        });

        $('html').on('click', function () {
            //hide elements if visible
            var searchSuggestWrapper = $('#itemsearch_suggestion_wrapper');

            if (typeof searchSuggestWrapper !== 'undefined' && $(searchSuggestWrapper).is(':visible')) {
                $(searchSuggestWrapper).empty().hide();
            }
        });

        $('#itemsearch_suggestion_wrapper,#form_search').on('click', function (event) {
            event.stopPropagation();
        });
    }
}

function showSearch(toggle,search){
    toggle.addClass('active');
    search.slideDown('fast');
}

function hideSearch(toggle,search){
    toggle.removeClass('active');
    search.slideUp('fast');
}
$(document).ready(function () {
    $('.slidecontent_headline').click(function () {
        $(this).toggleClass('active');
        $(this).next('.slidecontent_content_container').slideToggle('fast');
    });
});
function spinUp(element) {
    var input = element.closest('.quantity').find('input[type=number]');
    var val = parseInt(input.val());
    var max = 0;
    var step = 1;
    var isDisabled = false;

    if (input.attr('max') != undefined) {
        max = parseInt(input.attr('max'));
    }

    if (input.attr('step') != undefined) {
        step = parseInt(input.attr('step'));
    }

    if (input.attr('disabled') != undefined) {
        isDisabled = true;
        if (input.attr('disabled') == "disabled") {
            isDisabled = true;
        }
    }

    if (input.attr('readonly') != undefined) {
        isDisabled = true;
        if (input.attr('readonly') == "readonly") {
            isDisabled = true;
        }
    }

    if (!isDisabled) {
        if (max != undefined && max > 0) {
            if (max >= val + step - (val % step)) {
                input.val(val + step - (val % step));
            }
        } else {
            input.val(val + step - (val % step));
        }
    }
}

function spinDown(element) {
    var input = element.closest('.quantity').children('input[type=number]');
    var val = parseInt(input.val());
    var min = 0;
    var step = 1;
    var isDisabled = false;

    if (input.attr('min') != undefined) {
        min = parseInt(input.attr('min'));
    }

    if (input.attr('step') != undefined) {
        step = parseInt(input.attr('step'));
    }

    if (input.attr('disabled') != undefined) {
        isDisabled = true;
        if (input.attr('disabled') == "disabled") {
            isDisabled = true;
        }
    }

    if (input.attr('readonly') != undefined) {
        isDisabled = true;
        if (input.attr('readonly') == "readonly") {
            isDisabled = true;
        }
    }

    if (!isDisabled) {
        if (((val - step) % step) > 0) {
            if (min <= val - (val % step)) {
                input.val(val - (val % step))
            }
        } else {
            if (min <= val - step) {
                input.val(val - step)
            }
        }
    }
}

$(document).on('click', '.spinner_up', function () {
    spinUp($(this));
});
$(document).on('click', '.spinner_down', function () {
    spinDown($(this));
});

function stickyHeader(header, stickyclass, spaceHelperEl) {
    var startSticky = 0;

    checkSticky(header, stickyclass, startSticky, spaceHelperEl);

    $(window).scroll(function () {
        checkSticky(header, stickyclass, startSticky, spaceHelperEl);
    });
    $(window).resize(function () {
        startSticky = 0;
        checkSticky(header, stickyclass, startSticky, spaceHelperEl);
    });
}

function checkSticky(header, stickyclass, startSticky, spaceHelperEl) {
    if ($(window).scrollTop() > startSticky) {
        var position = header.css('position');
        //adds sticky helper for creating free space. If header is sticky, the content will not move.
        if (position !== "fixed" && header.prev('.sticky-helper').length < 1) {
            //var heightStickyHelper = parseInt(spaceHelperEl.outerHeight());
            var heightStickyHelper = 91;
            header.before('<div class="sticky-helper" style="height:' + (heightStickyHelper) + 'px"></div>');
        }
        header.addClass(stickyclass);
    } else {
        header.removeClass(stickyclass);
        $('.sticky-helper').remove();
    }
}

function stickySubnavi(subNavi, stickyclass) {
    var startSticky = (subNavi.position().top - $('#header').outerHeight());

    checkSubnavi(subNavi, stickyclass, startSticky);

    $(window).scroll(function () {
        checkSubnavi(subNavi, stickyclass, startSticky);
    });
    $(window).resize(function () {
        startSticky = (subNavi.position().top - $('#header').outerHeight());
        checkSubnavi(subNavi, stickyclass, startSticky);
    });
}

function checkSubnavi(subNavi, stickyclass, startSticky) {
    if ($(window).scrollTop() > startSticky) {
        var position = subNavi.css('position');
        //adds sticky helper for creating free space. If header is sticky, the content will not move.
        if (position !== "fixed" && subNavi.prev('.sticky-helper').length < 1) {
            var heightStickyHelper = parseInt(subNavi.outerHeight());
            subNavi.before('<div class="sticky-helper2" style="height:' + (heightStickyHelper + 64) + 'px"></div>');
        }
        subNavi.addClass(stickyclass);
        subNavi.css('top', $('#header').outerHeight());
    } else {
        subNavi.removeClass(stickyclass);
        $('.sticky-helper2').remove();
        subNavi.css('top', '');
    }
}
$(document).ready(function () {
    if ($(window).width() < 768) {
        $('.textcontent table').wrap('<div class=\"table_wrapper\"></div>');
    }
});
$(document).ready(function () {
    //script to toggle Classes for multiple situations
    /*
    * data-toggletar --> CSS Selector for the Element that should have a toggling class (Multiple, comma separated classes possible --> CSS Default)
    * data-toggleclass --> Name of the toggling class
    * if you want to close a element only you should use a data-toggletar like: "#targetID.active"
    *
    * Example HTML
    * <div class="js-toggleClass" data-toggletar="#targetID, .targetclass" data-toggleclass="active"></div>
    * <div id="targetID"></div>
    * */
    $('.js-toggleClass').on('click', function () {
        var toggleTar = $(this).data('toggletar');
        var toggleClass = $(this).data('toggleclass');
        $(toggleTar).toggleClass(toggleClass);
    })
});
$(document).ready(function () {
    $('.toggleTable').click(function (e) {
        if (!$(e.target).hasClass('button')) {
            $(this).find('.toggleTableRow:not(.isfirst)').slideToggle('fast');
            $(this).toggleClass('active');
        }
    });
});

function showUl() {
    $(this).addClass('hoverintent');
}

function hideUl() {
    $(this).removeClass('hoverintent');
}

function showUlPrimary() {
    $(this).addClass('hoverintent');
    if($(this).children('ul').children('.arrow').length == 0){
        $(this).children('ul').append("<div class='arrow'></div>");
        var arrow = $(this).children('ul').children('.arrow');
        arrow.css('left',$(this).position().left + $(this).width()/2 + 'px');
    }
}

function hideUlPrimary() {
    $(this).removeClass('hoverintent');
}

function showItemlistNoExt() {
    $(this).addClass('hoverintent');
}

function hideItemlistNoExt() {
    $(this).removeClass('hoverintent');
}

function showItemlistExt() {
    var actualElem = $(this);

    if (!actualElem.parents('.random_item_list').length) {
        if (actualElem.hasClass('has_variant_pictures')) {
            var variantsWidth = actualElem.find('.itemlist_variants').outerWidth();
            actualElem.find('.itemlist_variants').css('max-height',actualElem.children('.itemlist_item').outerHeight());
            actualElem.css({
                left:  -variantsWidth,
                width: actualElem.outerWidth()+variantsWidth,
                'padding-left': variantsWidth
            });
        }else{
        }
        actualElem.addClass('hoverintent');
        actualElem.find('.itemlist_extended').show();
    }
}

function hideItemlistExt() {
    var actualElem = $(this);

    if (!actualElem.parents('.random_item_list').length) {
        if (actualElem.hasClass('has_variant_pictures')) {
            actualElem.css({
                left: '',
                width: '',
                'padding-left': ''
            });
            actualElem.find('.itemlist_variants').css('max-height','');
        }
        actualElem.find('.itemlist_extended').hide();
        actualElem.removeClass('hoverintent');
    }
}

function scrolling(tabcontent_id) {
    var ziel = $(tabcontent_id);
    var top = ziel.offset().top;
    var heightHeader = $('#header').outerHeight();
    $('html,body').animate({
        scrollTop: top - heightHeader
    }, 800);
}

function fixHeightCollection(){
    $('.collection-history').each(function(){
        var highestHeadlineElement = 0;
        $(this).find('.collection__headline').each(function (){
            var currentHeadlineElementHeight = $(this).outerHeight();
            if (currentHeadlineElementHeight > highestHeadlineElement){
                highestHeadlineElement = currentHeadlineElementHeight;
            }
        });
        $('.collection-history').find('.collection__headline').css('min-height', highestHeadlineElement);
    });

    $('.collection-news').each(function(){
        var highestNewsElement = 0;
        var highestNewsContentElement = 0;
        $(this).find('.collection__headline').each(function (){
            var currentNewsElementHeight = $(this).outerHeight();
            if (currentNewsElementHeight > highestNewsElement){
                highestNewsElement = currentNewsElementHeight;
            }
        });
        $(this).find('.collection__content').each(function (){
            var currentNewsContentElementHeight = $(this).outerHeight();
            if (currentNewsContentElementHeight > highestNewsContentElement){
                highestNewsContentElement = currentNewsContentElementHeight;
            }
        });
        $('.collection-news').find('.collection__headline').css('min-height', highestNewsElement);
        $('.collection-news').find('.collection__content').css('min-height', highestNewsContentElement);
    });

    $('.collection-blog').each(function(){
        var highestBlogElement = 0;
        $(this).find('.collection__headline').each(function (){
            var currentBlogElementHeight = $(this).outerHeight();
            if (currentBlogElementHeight > highestBlogElement){
                highestBlogElement = currentBlogElementHeight;
            }
        });
        $('.collection-blog').find('.collection__headline').css('min-height', highestBlogElement);
    });
}

function fixHeightCMSElements(){
    if ($(window).width() > 767) {
        $('.tabNavigation__wrapper').each(function(){
            var highestHeadlineElement = 0;
            $(this).find('.tabNavigation__tabs').each(function (){
                var currentHeadlineElementHeight = $(this).outerHeight();
                if (currentHeadlineElementHeight > highestHeadlineElement){
                    highestHeadlineElement = currentHeadlineElementHeight;
                }
            });
            $('.tabNavigation__wrapper').find('.tabNavigation__tabs').css('min-height', highestHeadlineElement);
        });
    }

}

function showTabNavigationContent(trigger) {
    const iconnavigation = trigger.closest('.tabNavigation');
    let index = [...trigger.parentElement.querySelectorAll(':scope > *')].indexOf(trigger);
    const iconwrapper = trigger.closest('.tabNavigation__wrapper');

    if (!iconnavigation) {
        return;
    }

    if (!iconwrapper) {
        return;
    }

    if (!index) {
        index = 0;
    }

    const contentwrapper = iconwrapper.querySelector('.tabNavigation__tabs');

    if (!contentwrapper) {
        return;
    }
    const arrContents = contentwrapper.querySelectorAll(':scope > *');

    if (!arrContents) {
        return;
    }

    if (!trigger.classList.contains('active')) {
        iconnavigation.querySelector('.active').classList.remove('active');
        trigger.classList.add('active');
        scrollToActiveTabNavigation(trigger);
        for (let content of arrContents) {
            content.style.display = "none";
        }
        if (arrContents[index]) {
            arrContents[index].style.display = "block";
        }
    }
}

function scrollToActiveTabNavigation(trigger) {
    const iconnavigation = trigger.closest('.tabNavigation');
    let positionLeft = trigger.getBoundingClientRect().x;

    iconnavigation.scrollTo({
        top: 0,
        left: positionLeft,
        behavior: 'smooth'
    });
}

$(document).ready(function () {
    var header = $('#header');
    var subnavigation = $('.stickySubnavigation');
    var mobilemenu = $('#primary_navigation_mobile');
    var toggleNavigation = $('#toggle_navigation');
    var overlay = $('#overlay');
    var toggleMobileSearch = $('.js-searchButton');
    var headerSearch = $('.header_search__wrapper');

    stickyHeader(header,"sticky",header);

    if (subnavigation.length) {
        if (window.screen.width >= 768) {
            stickySubnavi(subnavigation, 'sticky');
        } else {
            let stickySubnavigationParent = document.querySelector('.stickySubnavigation').closest('.group');

            if (stickySubnavigationParent) {
                stickySubnavigationParent.style.marginTop = "36px";
            }
        }
    }

    // give navigation with 3 child uls a new class
    let thirdLevelNavigationList = document.querySelectorAll('.header_navigation ul.level_3');
    thirdLevelNavigationList.forEach(function(thirdLevelNavigation) {
        thirdLevelNavigation.closest("li.level_1").classList.add('hasThreeChildren');
    });

    //animation Clippath Scroller
    animateAndScroll({
        wrapper: ".animatedClippath",
        els: [{
            selector: ".clippath__front",
            states: [
                {
                    start: -25,
                    end: 100,
                    options: {
                        "-webkit-mask-size": "250vw"
                    }
                }
            ]
        },{
            selector: ".clippath__front div",
            states: [
                {
                    start: -25,
                    end: 100,
                    options: {
                        opacity: 1
                    }
                }
            ]
        }
        ]
    });

    $('.iconTiles').owlCarousel({
        autoplay: true,
        loop: true,
        nav: false,
        dots: false,
        margin: 80,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1024: {
                items: 3,
            },
            1368: {
                items: 4,
            },
        }
    });

    fixHeightCollection();

    fixHeightCMSElements();

    addToggleButtonForMobileMenu(mobilemenu);

    $('.header_emergency_but').click(function () {
        $(this).toggleClass('openEmergency');
    });

    initScrolltopbutton($('#scrolltop_button'));

    $('#primary_navigation_mobile .close_button_navigation_mobile, #overlay').click(function () {
        hide_mobile_menu(mobilemenu, overlay, toggleNavigation);
    });

    $('#primary_navigation_mobile .jumpmark').click(function () {
        hide_mobile_menu(mobilemenu, overlay, toggleNavigation);
    });

    var pathname = window.location.pathname;
    var linkSelector = $('a[href="' + pathname + '"');
    if (pathname.length > 3) {
        linkSelector.addClass('nav-active');
        linkSelector.parents('li').addClass('nav-active_tree');
        linkSelector.parents('ul').addClass('nav-active_tree');
    }

    toggleNavigation.click(function () {
        if (!mobilemenu.hasClass('active')) {
            show_mobile_menu(mobilemenu, overlay, toggleNavigation);
            hideSearch(toggleMobileSearch,headerSearch);
        } else {
            hide_mobile_menu(mobilemenu, overlay, toggleNavigation);
        }
    });

    toggleMobileSearch.click(function (){
        if(toggleMobileSearch.hasClass('active')){
            hideSearch(toggleMobileSearch,headerSearch);
        }else{
            showSearch(toggleMobileSearch,headerSearch);
        }
    });

    headerSearch.on('click',function (e) {
        if (!$('#search').is(e.target) && !$('input').is(e.target)) {
            hideSearch(toggleMobileSearch,headerSearch);
        }
    });

    $("#form_search").submit(function (e) {
        if (!$("#form_search").find('#input_search').val()) {
            e.preventDefault(e);
            e.stopPropagation(e);
        }
    });

    //initSearchSuggest();

    $('.input_quantity_line').keydown(function (event) {
        if (event.keyCode == 13) {
            this.form.submit();
            return false;
        }
    });

    if($('.collection_container .link-actions').length > 0) {
        $('.collection_container').each(function(){
            var linkcontainer = $(this).find('.link-actions');
           var link = linkcontainer.children('a');
           $(this).wrap('<a href=\"'+link.attr('href')+'\"></a>');
            linkcontainer.remove();
        });
    }

    $('.itemlist_order_button_link').on('click',function(e) {
        e.preventDefault();
        e.stopPropagation();

        if (($(this).attr('data-disabled') !== 'disabled')) {

            var parser = document.createElement('a');
            parser.href = window.location.href;

            var search = parser.search;
            search = search.replace('?action=shop_add_item_to_basket_list', '').replace('&action=shop_add_item_to_basket_list', '');

            var formAction = '?';
            if (search != '') {
                formAction = search + '&'
            }

            formAction += "action=shop_add_item_to_basket_list";
            $('<input>').attr({
                type: 'hidden',
                id: 'item_qty',
                name: 'item_qty',
                value: $(this).closest('form').find('.input_item_quantity_value').val()
            }).appendTo($(this).closest('form'));
            $('<input>').attr({
                type: 'hidden',
                id: 'item_id',
                name: 'item_id',
                value: $(this).closest('form').find('.input_item_id').val()
            }).appendTo($(this).closest('form'));
            $(this).closest('form').attr("action", formAction);
            $(this).closest('form').submit();
        }
    });

    $('.header_navigation li.level_1').hoverIntent({
        over: showUl,
        timeout: 200,
        out: hideUl
    });

    var navigationAddOn = $('#header .navigationAddon');
    navigationAddOn.find('.component').each(function() {
        var componentCode = $(this).data("componentcode");
        $('.header_navigation').find('.' + componentCode).prepend($(this));
    });

    $('#header').find(' li').each(function () {
        if ($(this).children('ul').length > 0) {
            $(this).addClass('hasChildren');
        }
    });

    for (let item of document.querySelectorAll('.tabNavigation > li')) {
        item.addEventListener('click', function () {
            showTabNavigationContent(item);
        });
    }
});
$(document).on('click', 'a.jumpmark', function (event) {
    event.preventDefault();

    const href = $(this).attr('href');
    const isExternal = href.startsWith('http') && !href.startsWith(window.location.origin);
    const hash = href.split('#')[1];

    if (isExternal) {
        // Navigate to the external link
        window.location.href = href;
    } else {
        if (href === "#") {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        } else {
            const targetElement = $('#' + hash);
            if (targetElement.length) {
                const header = $('.header');
                let headerHeight = 0;
                if (header.length > 0) {
                    headerHeight = header.outerHeight();
                }
                $('html, body').animate({
                    scrollTop: targetElement.offset().top - headerHeight
                }, 500);
            } else {
                // Navigate to the link
                window.location.href = href;
            }
        }
    }
});

// On the target page, handle the smooth scroll to the anchor
$(document).ready(function() {
    const hash = window.location.hash.substring(1);
    if (hash) {
        const header = $('.header');
        let headerHeight = 0;
        if (header.length > 0) {
            headerHeight = header.outerHeight();
        }
        const targetElement = $('#' + hash);
        if (targetElement.length) {
            $('html, body').animate({
                scrollTop: targetElement.offset().top - headerHeight
            }, 500);
        }
    }
});




function ready(fn) {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}
ready(() => {

    initContactBanner();

});

function initContactBanner() {

    for (let contactBanner of document.querySelectorAll('.contactBanner')) {
        contactBanner.querySelector('.icon-angle-down').addEventListener('click',() => {
            closeAllContactBanner();
            contactBanner.classList.add('is-active');
            console.log('click');
        });

        contactBanner.querySelector('.icon-angle-up').addEventListener('click',() => {
            contactBanner.classList.remove('is-active');
        });
    }

}

function closeAllContactBanner() {

    for (let contactBanner of document.querySelectorAll('.contactBanner')) {
        if (contactBanner.classList.contains('is-active')) {
            contactBanner.classList.remove('is-active');
        }
    }

}




